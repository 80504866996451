<template>
  <div
    class="flex flex-col rounded-2xl border p-4"
    :class="{
      'border-brick bg-white': variant === 'white',
      'border-gray-200 bg-brick-50 transition duration-300 ease-in-out': variant === 'default',
      'cursor-pointer hover:border-brick hover:bg-brick-100': link,
    }"
    @click.prevent="handleSavingsCardClick">
    <div class="flex items-center justify-between">
      <h4 class="text-sm text-brick-700">dein Termin</h4>
      <AppPill class="self-start bg-green-100 font-medium text-green-700">Spare {{ discount }}&nbsp;%</AppPill>
    </div>
    <div class="flex items-center gap-2">
      <p class="text-2xl font-medium text-gray-700">{{ transformToCurrency(reducedPrice) }}</p>
      <p class="font-medium text-red-600 line-through">{{ transformToCurrency(price) }}</p>
    </div>
    <p class="text-sm text-green-600">{{ transformToCurrency(savings) }} gespart</p>
    <p v-if="link" class="mt-2 text-sm">
      Du bist dir nicht sicher? Überzeuge dich auf der
      <AppLink :to="membershipPageLink" target="_blank">Mitgliedschaft Seite</AppLink>.
    </p>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    price: number;
    reducedPrice: number;
    savings: number;
    discount: number;
    link?: boolean;
    trackingParameter?: string;
    variant?: "default" | "white";
  }>(),
  {
    link: false,
    trackingParameter: "quick-checkout",
    variant: "default",
  },
);

const membershipPageLink = computed(() => `/abo?mtm_campaign=${props.trackingParameter}`);

async function handleSavingsCardClick(): Promise<void> {
  if (props.link === false) {
    return;
  }

  await navigateTo(membershipPageLink.value, {
    open: {
      target: "_blank",
    },
  });
}
</script>

<style scoped></style>
