<template>
  <div class="relative w-full" data-carousel="static">
    <!-- Carousel wrapper -->
    <div class="relative h-[21rem] overflow-hidden rounded-lg md:h-96">
      <div v-for="(item, index) in data" :key="index" class="hidden duration-700 ease-in-out" data-carousel-item>
        <div class="absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2">
          <SharedCarouselItem :data="item" />
        </div>
      </div>
    </div>
    <!-- Slider controls -->
    <button
      type="button"
      class="group absolute left-0 top-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none"
      data-carousel-prev>
      <span
        class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-brick/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white">
        <SvgoChevronRight class="rotate-180 text-2xl text-gray-700" />
        <span class="sr-only">Previous</span>
      </span>
    </button>
    <button
      type="button"
      class="group absolute right-0 top-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none"
      data-carousel-next>
      <span
        class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-brick/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white">
        <SvgoChevronRight class="text-2xl text-gray-700" />
        <span class="sr-only">Next</span>
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { initCarousels } from "flowbite";
import type { TestimonialPerPage } from "~/components/block/Testimonial.vue";

defineProps<{
  data: TestimonialPerPage;
}>();

onMounted(() => {
  useFlowbite(() => {
    initCarousels();
  });
});
</script>

<style lang="scss" scoped></style>
