<template>
  <div class="inline">
    <LazySharedDrawer ref="drawer" @close="onClose">
      <div class="flex flex-col gap-6">
        <h3 class="mr-4 text-2xl leading-tight">Teste haelsi 1 Monat kostenlos!</h3>
        <template v-if="price && reducedPrice && savings && discount">
          <div>
            <p class="mb-2">Sofort sparen und alle Vorteile nutzen</p>
            <SharedSavingsCard :price="price" :reduced-price="reducedPrice" :savings="savings" :discount="discount" />
          </div>
        </template>

        <div class="flex flex-col gap-y-2 font-medium text-gray-600">
          <AppListItem type="checkmark">25 % Rabatt auf jeden Arztbesuch</AppListItem>
          <AppListItem type="checkmark">30 % auf jeden Therapietermin</AppListItem>
          <AppListItem type="checkmark">Hohe Rückerstattung</AppListItem>
          <AppListItem type="checkmark">Familienmitgliedschaft</AppListItem>
        </div>

        <p class="text-lg text-gray-800">
          Schon über <span class="font-medium">5.000 Patient:innen</span> nutzen haelsi und sparen bei jedem Termin
        </p>

        <hr class="border-brick-400" />

        <div>
          <p class="text-lg font-medium text-gray-800">Moment, wie ...</p>
          <BuilderIoFaqItemsById
            size="small"
            variant="small"
            :faq-item-ids="[{ id: 349 }, { id: 96 }, { id: 100 }, { id: 252 }, { id: 101 }]" />
        </div>
      </div>
    </LazySharedDrawer>
  </div>
</template>

<script setup lang="ts">
const membershipDrawerStore = useMembershipDrawerStore();
const price = computed(() => membershipDrawerStore.price);
const discount = computed(() => membershipDrawerStore.discount);

const reducedPrice = computed(() =>
  price.value && discount.value ? roundToPrecision(price.value * (1 - discount.value / 100), 2) : undefined,
);
const savings = computed(() =>
  price.value && reducedPrice.value ? roundToPrecision(price.value - reducedPrice.value, 2) : undefined,
);

membershipDrawerStore.$subscribe(
  (_mutation, state) => {
    if (state.isVisible === true) {
      handleOpen();
    }
  },
  { detached: true },
);
const drawer = ref();
function handleOpen(): void {
  drawer.value?.open();
}
function onClose(): void {
  membershipDrawerStore.close();
}
</script>

<style scoped></style>
