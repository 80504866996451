<template>
  <div
    class="mx-auto mt-0 max-w-screen-xl gap-8 px-5 py-8 md:grid md:grid-cols-12 md:gap-16"
    :class="{ 'items-center': hasImage }">
    <SharedSimpleImage
      v-if="hasImage"
      :data="data?.simpleImage"
      :class="{
        'md:order-last md:col-span-4': alignRight,
        'md:col-span-4': thirtySeventy,
        'md:col-span-6': even,
      }"
      class="col-span-12 mb-8 lg:mb-0" />
    <div
      class="col-span-12 leading-normal"
      :class="{
        'order-first md:col-span-6': alignRight,
        'md:col-span-8': thirtySeventy,
        'md:col-span-6': even,
      }">
      <h1
        class="hyphens-auto text-3xl leading-tight text-gray-900"
        :class="{ 'text-3xl md:text-4xl lg:text-5xl': titleIsPrimary }">
        {{ data?.header?.title.titleText }}
      </h1>
      <h5
        class="mt-3.5 w-full text-xl text-gray-900 antialiased md:font-normal"
        :class="{
          'text-3xl leading-tight md:text-4xl lg:text-5xl': titleIsSecondary,
        }">
        {{ data?.header?.subtitle.subtitleText }}
      </h5>

      <div v-if="hasFootnote" class="mb-8">
        <SvgoInfo class="text-xl" data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom" />
        <div
          id="tooltip-bottom"
          role="tooltip"
          class="tooltip invisible absolute z-10 inline-block max-w-xl rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm">
          {{ data?.footnote }}
          <div class="tooltip-arrow" data-popper-arrow />
        </div>
      </div>

      <SharedButton
        v-if="data?.header?.button"
        :data="data.header.button"
        class="mt-4 px-2.5 py-2 text-sm lg:px-6 lg:py-3" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { initTooltips } from "flowbite";
import { computed, onMounted } from "vue";
import type { SimpleContent } from "~/types/strapi/components/block/interfaces/SimpleContent";

onMounted(() => {
  useFlowbite(() => {
    initTooltips();
  });
});

const props = defineProps<{
  data: SimpleContent;
}>();

const alignRight = computed(() => props.data?.imagePosition === "right");
const hasImage = computed(() => props.data?.simpleImage?.image.data !== null);

const titleIsPrimary = computed(() => props.data?.header?.title.theme === "primary");

const titleIsSecondary = computed(() => props.data?.header?.title.theme === "secondary");

const even = props.data?.ratio === "even";
const thirtySeventy = props.data?.ratio === "thirtySeventy";

const hasFootnote = computed(() => props.data?.footnote !== null);
</script>

<style scoped></style>
