export const couponLinkWienerStaedtische =
  "https://besserleben.wienerstaedtische.at/de/edition/vorsorge/mediclass-gesundheitsclub";

export const additionalPreventiveCareFee = 110;
export const preventiveCareBasicPrice = 295;
export const preventiveCarePrice = 395;
export const officialPreventiveCarePrice = 590;

export const labBookingName = "Labortermin";
export const healthProfessionalBookingName = "Untersuchungstermin";

export const locationId = 2;
